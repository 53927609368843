.pre_day, .cal_day, .dnd_day {
  width: calc(100% / 7 - 2px);
  height: calc(100% / 5);
  float:left;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.pre_day {
  height: calc(100% / 6);
}



.weekend {
  background: #80808026;
}

.highlightuser {
  opacity: 1;
}

.unhighlightuser {
  opacity: 0.2;
}

/*_weeks*/
.inner_4 {
  height: 25%
}
.inner_5 {
  height: 20%
}
.inner_6 {
  height: 17%
}

/* _weeks_maxoffers*/
.dnd_day.outer_4_ {
  min-height: 25%
}

.dnd_day.outer_5_ {
  min-height: 20%
}

.dnd_day.outer_6_ {
  min-height: 17%
}


.dayshift, .nightshift {
  height : 50%;
  position: relative;
  overflow: hidden;
}

.nigshift {
  margin-top: 50%;
}


.day_nr {
  position: absolute;
  z-index: 0;
  color: #5c6bff;
  font-size: 80%;
  }

.dtday, .dtnight{
  //border: 1px dotted blue;
  width: 100%;
  height: 20px;
  background: #5570ff85;
}

.dtday {
    border-top: 1px solid black;
}

.dtnight {
  background: #2648ff85;
  border-bottom: 1px solid black;
}



.dtoffer {
  width: 100%;
  min-height: 60%;
  height: calc( 100% - 43px);
  background: #00000024;
}

.dtoffer.inners {
  overflow-y : auto;
}

.dtoffer.outers {
  overflow-y : hidden;
}


.shift_ND {
  background: #667df5;
  color: black;
  padding-left: 16px;
  overflow-x: hidden;
  white-space: nowrap;
}

.shift_TD {
  background : #f6a51a;
  padding-left: 16px;
  overflow-x: hidden;
  white-space: nowrap;
}

.twin {
  color: white;
  margin-top: -20px;
  display: block;
  background: none;
}

.twin .left, .twin .right {
  width:50%;
}

.shift_TD.twin {
  width: 50%;
  z-index: 15;
  margin-top: -20px;
  background : #f6a51a;
  overflow-x: hidden;
  white-space: nowrap;
}

.dbaulistview {
  width: 110%;
  min-height: 30px;
  padding-top: 7%;
  background: #b8c2f71c;
  border: none;

}

.offercapt {
  font-size: 5vw;
  vertical-align: centte;
  padding-top: 5px;
  color: #7475ff4a;
  text-align: center;
}
