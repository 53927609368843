.Calendar {
  width: 100vw;
}

.cal_head, .cal_body {
  display:  inline-block;
  width: 100%;
}

.cal_body {
  height: 75vh;
}

.cal_body_dark {
  /* opacity: 0.8;  */
}


.wota {
  width: calc(100% / 7 - 2px);
  float:left;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-top: 2px;
  color: white;
  text-align: center;
  -webkit-box-shadow: inset 0px 1px 0px 0px #aacee6;
box-shadow: inset 0px 1px 0px 0px #aacee6;
background: -webkit-gradient(linear, left top, left bottom, color-stop(0.05, #65a5d3), color-stop(1, #aacee6));
background: -moz-linear-gradient(top, #3f51b5 5%, #3f51b5c7 100%);
background: -webkit-linear-gradient(top, #3f51b5 5%, #3f51b5c7 100%);
background: -o-linear-gradient(top, #3f51b5 5%, #3f51b5c7 100%);
background: -ms-linear-gradient(top, #3f51b5 5%, #3f51b5c7 100%);
background: linear-gradient(to bottom, #3f51b5 5%, #3f51b5c7 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#65a5d3', endColorstr='#aacee6',GradientType=0);
  padding-left:  auto;
  padding-right: auto;
}

.wt_Sa  {
  background : #eaeaea91;
}
.wt_So, .wt_FT {
  background :#dadadab8;
}

.printviewownshift {
  background: #ff00004d;
}
