.incolor {
  border-left: 4px solid green !important;
}

.outcolor {
    border-left: 4px solid red !important;
}

.mixedcolor {
    border-left: 5px solid !important;
    -webkit-border-image:
      -webkit-linear-gradient(bottom, red, green) 1 100% !important;
    -moz-border-image:
      -moz-linear-gradient(bottom, red, green) 1 100% !important;
    -o-border-image:
      -o-linear-gradient(bottom, red, green) 1 100% !important;
    border-image:
      linear-gradient(to top, red, green) 1 100% !important;

}
.list_shift {
  padding:15px;
}

.list_name {
  padding: 3px;
}
