.shiftchoose {
  /*margin: 3px 15px 0px 0px;*/
  padding: 2px 5px;
  min-height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.onewpselect {
  width: 40%;
}
.shiftcheckboxes {
  position: relative;
  left: 20px;
  width: 85px;
}

#form-dialog-title {
  text-align: center;
}
