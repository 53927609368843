body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.list_has_cert {
  background-color: rgba(1, 255, 1, 0.274);
}

.ficustom {
  width: 100%;
}
.pre_day, .cal_day, .dnd_day {
  width: calc(100% / 7 - 2px);
  height: calc(100% / 5);
  float:left;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.pre_day {
  height: calc(100% / 6);
}



.weekend {
  background: #80808026;
}

.highlightuser {
  opacity: 1;
}

.unhighlightuser {
  opacity: 0.2;
}

/*_weeks*/
.inner_4 {
  height: 25%
}
.inner_5 {
  height: 20%
}
.inner_6 {
  height: 17%
}

/* _weeks_maxoffers*/
.dnd_day.outer_4_ {
  min-height: 25%
}

.dnd_day.outer_5_ {
  min-height: 20%
}

.dnd_day.outer_6_ {
  min-height: 17%
}


.dayshift, .nightshift {
  height : 50%;
  position: relative;
  overflow: hidden;
}

.nigshift {
  margin-top: 50%;
}


.day_nr {
  position: absolute;
  z-index: 0;
  color: #5c6bff;
  font-size: 80%;
  }

.dtday, .dtnight{
  //border: 1px dotted blue;
  width: 100%;
  height: 20px;
  background: #5570ff85;
}

.dtday {
    border-top: 1px solid black;
}

.dtnight {
  background: #2648ff85;
  border-bottom: 1px solid black;
}



.dtoffer {
  width: 100%;
  min-height: 60%;
  height: calc( 100% - 43px);
  background: #00000024;
}

.dtoffer.inners {
  overflow-y : auto;
}

.dtoffer.outers {
  overflow-y : hidden;
}


.shift_ND {
  background: #667df5;
  color: black;
  padding-left: 16px;
  overflow-x: hidden;
  white-space: nowrap;
}

.shift_TD {
  background : #f6a51a;
  padding-left: 16px;
  overflow-x: hidden;
  white-space: nowrap;
}

.twin {
  color: white;
  margin-top: -20px;
  display: block;
  background: none;
}

.twin .left, .twin .right {
  width:50%;
}

.shift_TD.twin {
  width: 50%;
  z-index: 15;
  margin-top: -20px;
  background : #f6a51a;
  overflow-x: hidden;
  white-space: nowrap;
}

.dbaulistview {
  width: 110%;
  min-height: 30px;
  padding-top: 7%;
  background: #b8c2f71c;
  border: none;

}

.offercapt {
  font-size: 5vw;
  vertical-align: centte;
  padding-top: 5px;
  color: #7475ff4a;
  text-align: center;
}

.shiftchoose {
  /*margin: 3px 15px 0px 0px;*/
  padding: 2px 5px;
  min-height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.onewpselect {
  width: 40%;
}
.shiftcheckboxes {
  position: relative;
  left: 20px;
  width: 85px;
}

#form-dialog-title {
  text-align: center;
}

.Calendar {
  width: 100vw;
}

.cal_head, .cal_body {
  display:  inline-block;
  width: 100%;
}

.cal_body {
  height: 75vh;
}

.cal_body_dark {
  /* opacity: 0.8;  */
}


.wota {
  width: calc(100% / 7 - 2px);
  float:left;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-top: 2px;
  color: white;
  text-align: center;
box-shadow: inset 0px 1px 0px 0px #aacee6;
background: linear-gradient(to bottom, #3f51b5 5%, #3f51b5c7 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#65a5d3', endColorstr='#aacee6',GradientType=0);
  padding-left:  auto;
  padding-right: auto;
}

.wt_Sa  {
  background : #eaeaea91;
}
.wt_So, .wt_FT {
  background :#dadadab8;
}

.printviewownshift {
  background: #ff00004d;
}

.incolor {
  border-left: 4px solid green !important;
}

.outcolor {
    border-left: 4px solid red !important;
}

.mixedcolor {
    border-left: 5px solid !important;
    border-image:
      linear-gradient(to top, red, green) 1 100% !important;

}
.list_shift {
  padding:15px;
}

.list_name {
  padding: 3px;
}

.list_name {
  padding-left: 3px;
}

.list_shift {
  padding-left: 15px;
}

.editable {
  color :  red;  /*'#045404'*/
}


.paddingDense {
  padding: 5px 5px 5px 5px;
}

/**
 * We inherit the height of the container by default
 */

 .react-fine-uploader-total-progress-bar {
   height: 4px;
   background: #0000ff8a;
 }

.selectelement {
  z-index: 100 !important;
}

.modbadge {
  width: 60px;
  height: 30px;
}

.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.DraftEditor-root {
  position: relative;
}

/**
 * Zero-opacity background used to allow focus in IE. Otherwise, clicks
 * fall through to the placeholder.
 */

.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  /* Repair mysterious missing Safari cursor */
  border: 1px solid transparent;
  position: relative;
  z-index: 1;

}

.public-DraftEditor-content {
  outline: none;
  white-space: pre-wrap;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftEditor-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}

.DraftEditor-alignCenter .public-DraftEditor-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.DraftEditor-alignRight .public-DraftEditor-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}
/**
 * @providesModule DraftEditorPlaceholder
 */

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 0;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}
/**
 * @providesModule DraftStyleDefault
 */

.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}

/* @noflip */

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

/* @noflip */

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

/**
 * These rules provide appropriate text direction for counter pseudo-elements.
 */

/* @noflip */

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

/* @noflip */

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

/**
 * Default spacing for list container elements. Override with CSS as needed.
 */

.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  margin: 16px 0;
  padding: 0;
}

/**
 * Default counters and styles are provided for five levels of nesting.
 * If you require nesting beyond that level, you should use your own CSS
 * classes to do so. If you care about handling RTL languages, the rules you
 * create should look a lot like these.
 */

/* @noflip */

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

/* @noflip */

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

/* @noflip */

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

/* @noflip */

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

/* @noflip */

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

/* @noflip */

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

/* @noflip */

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

/* @noflip */

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

/* @noflip */

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

/* @noflip */

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

/**
 * Only use `square` list-style after the first two levels.
 */

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

/**
 * Ordered list item counters are managed with CSS, since all list nesting is
 * purely visual.
 */

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

/* @noflip */

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}

/* @noflip */

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}

/**
 * Counters are reset in JavaScript. If you need different counter styles,
 * override these rules. If you need more nesting, create your own rules to
 * do so.
 */

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

